.routes-list {
    .selected-route-list-item{
        min-height: 150px;
        background: $color-primary;
        color: white;
        position: relative;
        padding: 20px;
        border-radius: 10px;
        margin-top: 20px;
        .info{
            .title {
                font-size: 21px;
                font-weight: bold;
            }
            .distance {
                font-size: 18px;
                font-weight: bold;
            }
            .ellipsis{
                position: absolute;
                top: 10px;
                right: 10px;
                border: none;
                background: transparent;
                color: white;
                font-size: 21px;
                &:focus{
                    outline: none;
                }
            }
            .share-menu {
                position: absolute;
                width: 110px;
                height: 60px;
                top: 33px;
                right: -30px;
            }
            .share-menu .share-btn {
                position:absolute;
                top:50%;
                left:50%;
                transform:translate(-50%,-50%);
                width:40px;
                height:40px;
                background:$color-primary;
                color:white;
                font-size:20px;
                text-align:center;
                line-height:40px;
                border-radius:50%;
                z-index:2;
            }
            .share-menu svg.fa-times {
                display:none;
            }
            .share-menu.active svg.fa-ellipsis-h {
                display:none;
            }
            .share-menu.active svg.fa-times {
                display:inline;
            }
            .share-menu .social-btn {
                position:absolute;
                top:50%;
                left:50%;
                transform:translate(-50%,-50%);
                width:40px;
                height:40px;
                background:$color-primary;
                color:white;
                border-radius:50%;
                cursor:pointer;
                text-align:center;
                line-height:40px;
                font-size:20px;
                transition:all 200ms ease-in-out 0ms;
            }
            .share-menu.active div:nth-child(2){
                top:-25%;
                left:50%;
                transform:translate(74%,-45%);
                transition:all 150ms ease-in-out 0ms;
            }
            .share-menu.active a:nth-child(3){
                top:0%;
                left: 74%;
                transform: translate(9%, 35%);
                transition:all 150ms ease-in-out 80ms;
            }
            .share-menu.active div:nth-child(4){
                top:100%;
                left:100%;
                transform:translate(-60%,0%);
                transition:all 200ms ease-in-out 160ms;
            }
        }
    }
    .route-list-item {
        min-height: 150px;
        position: relative;
        background: white;
        color: $color-primary;
        padding: 20px;
        border-radius: 10px;
        margin-top: 20px;
        .info{
            .title {
                font-size: 21px;
                font-weight: bold;
            }
            .distance {
                font-size: 18px;
                font-weight: bold;
            }
            .ellipsis{
                position: absolute;
                top: 10px;
                right: 10px;
                border: none;
                background: transparent;
                color: $color-primary;
                font-size: 21px;
                &:focus{
                    outline: none;
                }
            }
            .share-menu {
                position: absolute;
                width: 110px;
                height: 60px;
                top: 33px;
                right: -30px;
            }
            .share-menu .share-btn {
                position:absolute;
                top:50%;
                left:50%;
                transform:translate(-50%,-50%);
                width:40px;
                height:40px;
                background:transparent;
                color:$color-primary;
                font-size:20px;
                text-align:center;
                line-height:40px;
                border-radius:50%;
                cursor:pointer;
                z-index:2;
            }
            .share-menu svg.fa-times {
                display:none;
            }
            .share-menu.active svg.fa-ellipsis-h {
                display:none;
            }
            .share-menu.active svg.fa-times {
                display:inline;
            }
            .share-menu .social-btn {
                position:absolute;
                top:50%;
                left:50%;
                transform:translate(-50%,-50%);
                width:40px;
                height:40px;
                background:transparent;
                color:transparent;
                border-radius:50%;
                cursor:pointer;
                text-align:center;
                line-height:40px;
                font-size:20px;
                transition:all 200ms ease-in-out 0ms;
            }
            .share-menu.active div:nth-child(2){
                top:-25%;
                left:50%;
                transform:translate(-50%,-100%);
                transition:all 100ms ease-in-out 0ms;
            }
            .share-menu.active div:nth-child(3){
                top:0%;
                left: 74%;
                transform: translate(9%, 35%);
                transition:all 100ms ease-in-out 80ms;
            }
            .share-menu.active div:nth-child(4){
                top:100%;
                left:100%;
                transform:translate(-50%,-50%);
                transition:all 100ms ease-in-out 160ms;
            }
        }
        &:hover{
            background: $color-primary;
            color: white;
            .ellipsis{
                color: white;

            }
        }
    }
    .add-route-button {
        display: block;
        border-radius: 10px;
        background: white;
        color:$color-primary;
        margin-top: 20px;
        padding: 20px;
        width: 100%;
        border: none;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        &:hover {
            background: $color-primary;
            color: white;

        }
    }
    .start-route-button{
        display: block;
        border-radius: 10px;
        background: white;
        color:$color-primary;
        margin-top: 20px;
        padding: 20px;
        width: 100%;
        border: none;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        &:hover {
            background: $color-primary;
            color: white;
        }
    }
    .route-group-title{
        margin-top: -100px;
        padding-bottom: 30px;
        font-size: 40px;
        color: $color-primary;
        margin-left: 15px;
    }
}

.scroll-target{
    position: absolute;
    bottom: -300px;
}