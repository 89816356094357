// Variables for color management

$color-primary: #2cae66;
$color-secondary: #2cae66;
$color-tertiary: #2cae66;

$color-accent-primary: #4f6f39;
$color-accent-secondary: #000000;
$color-accent-tertiary: #000000;

