.loading-indicator{
    height: 100vh;
    svg{
        height: 100%;
        margin: auto;
        display: flex;
    }
}
.map-container{
    position: relative;
    height: 100%;
    width: 100%;
    .google-map {
        width: 100%;
        height: 100vh;
    }
    .travelmode-select{
        select{
            border: solid 1px $color-primary;
            position: absolute;
            z-index: 2000;
        }
    }
    .google-map-directions{
        height: 200px;
        overflow-y: scroll;
        position: absolute;
        z-index: 1000;
        background: white;
        border-radius: 5px;
        margin: auto;
        left: 0;
        right: 0;
        top: 20px;
        width: 50%;
        border-radius: 10px;
        padding: 10px;
    }
    .show-directions{
        z-index: 1000;
        position: absolute;
        right: 20px;
        top: 20px;
        color: white;
        background-color: $color-primary;
        border-radius: 5px;
        padding: 10px 20px;
        border: none;
        font-size: 18px;
        font-weight: bold;
        &:focus{
            border: none !important;
            outline: none;
        }
        &:hover{
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
            transition: all 0.3s cubic-bezier(.25,.8,.25,1);
            background-color: white;
            color: $color-primary;
        }
    }
}
.add-route-page{
    .new-route-form {
        height: 100%;
        margin-bottom: 300px;
        position: relative;
        .form-label {
            font-size: 21px;
            color: $color-primary;
            font-weight: bold;
        }
        .form-group-container {
            background: white;
            border-radius: 10px;
            padding: 20px 40px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        }
        .import-input {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }
        .import-label {
            color: white;
            background-color: $color-primary;
            display: inline-block;
            cursor: pointer;
            padding: 10px;
            border-radius: 5px;
        }
        .travelmode-select{
             select{
                 border: solid 1px #f1f1f1;
                 border-radius: 5px;
                 padding: 5px 10px;
                 box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                 &:focus {
                     outline: none;
                 }
             }
         }
        .waypoint {
            color: $color-primary;
            background-color: white;
            margin: 10px 0px;
            border-radius: 5px;
            font-size: 21px;
            position: relative;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            svg{
                display: inline-block;
                margin-right: 20px;
                margin-left: 10px;
            }
            p{
                display: inline-block;
                margin: 10px 35px;
            }
            .sight-link {
                 font-size: 0.8rem;
            }
            button{
                color: $color-primary;
                font-size: 21px;
                border: none;
                position: absolute;
                right: 10px;
                background-color: transparent;
                padding: 0;
                height: 100%;
                &:focus{
                    outline: none;
                }
                svg{
                    margin-right: 0px;
                }
            }
            .waypoint-btn-1{
                margin-right: 90px;
            }
            &.is-stop {
                background-color: #f1f1f1;
            }
        }
        .optimize-btn {
            border-radius: 5px;
            border-color: $color-primary;
        }
        .add-route-button {
            display: block;
            border-radius: 10px;
            background: white;
            color:$color-primary;
            margin-top: 20px;
            padding: 20px;
            width: 100%;
            border: none;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            position: absolute;
            &:hover {
                background: $color-primary;
                color: white;
            }
        }
        .search-input {
            border: solid 2px white;
            border-radius: 5px;
            color: $color-primary;
            padding: 5px 10px;
            width: 100%;
            font-size: 18px;
            &:focus{
                color: $color-primary;
                border: solid 2px $color-primary;
                outline: none;
            }
            &:hover{
                color: $color-primary;
                border: solid 2px $color-primary;
                outline: none;
            }
        }
        .input-margin {
            margin-bottom: 300px;
        }
        .select-route{
            width: 100%;
            border-radius: 5px;
            border: solid $color-primary 1px;
            padding: 10px 5px;
            font-size: 16px;
        }
    }
}
