.page {
    background-color: rgb(238, 238, 238);
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 0;
    .page-title{
        margin-top: 20px;
        color: $color-primary;
        margin-left: 15px;
        font-weight: bold;
    }
}
