.login-page {
    background: $color-primary;
    color: white;
    .app-title {
        font-size: 4rem;
    }
    .app-sub-title {
        font-size: 1.5rem;
    }
    .login-form {

    }
}
.logout-page {
    background: $color-primary;
    height: 100vh;
}

.logout-modal {
    .modal-content {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        border: none;
        .logout-btn {
            width: 45%;
            margin: 2.5%;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        }
    }
}