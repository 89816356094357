:root {
    /* generic */
    --gutterSm: 0.4rem;
    --gutterMd: 0.8rem;
    --gutterLg: 1.6rem;
    --gutterXl: 2.4rem;
    --gutterXx: 7.2rem;
    --colorPrimary400: #7e57c2;
    --colorPrimary600: #5e35b1;
    --colorPrimary800: #4527a0;
    --fontFamily: "Dosis", sans-serif;
    --fontSizeSm: 1.2rem;
    --fontSizeMd: 1.6rem;
    --fontSizeLg: 2.1rem;
    --fontSizeXl: 2.8rem;
    --fontSizeXx: 3.6rem;
    --lineHeightSm: 1.1;
    --lineHeightMd: 1.8;
    --transitionDuration: 300ms;
    --transitionTF: cubic-bezier(0.645, 0.045, 0.355, 1);

    /* floated labels */
    --inputPaddingV: 10px;
    --inputPaddingH: 20px;
    --inputFontSize: 16px;
    --inputLineHeight: 1.8;
    --labelScaleFactor: 0.9;
    --labelDefaultPosY: 50%;
    --labelTransformedPosY: calc(
            (var(--labelDefaultPosY)) -
            (var(--inputPaddingV) * var(--labelScaleFactor)) -
            (var(--inputFontSize) * var(--inputLineHeight))
    );
    --inputTransitionDuration: var(--transitionDuration);
    --inputTransitionTF: var(--transitionTF);
}


.animated-input-wrapper {
    position: relative;
}

.input-text {
    display: block;
    margin: 0;
    padding: var(--inputPaddingV) var(--inputPaddingH);
    color: inherit;
    width: 100%;
    font-family: inherit;
    font-size: var(--inputFontSize);
    font-weight: inherit;
    line-height: var(--inputLineHeight);
    border: solid 1px #ced4da;
    border-radius: 5px;
    transition: box-shadow var(--transitionDuration);
}

.input-text::placeholder {
    color: #B0BEC5;
}

.input-text:focus {
    outline: none;
    border: solid 1px $color-primary;
}

.input-label {
    display: block;
    position: absolute;
    top: -10px;
    left: 1rem;
    color: $color-primary;
    font-family: inherit;
    font-size: var(--inputFontSize);
    font-weight: inherit;
    line-height: var(--inputLineHeight);
    opacity: 0;
    transform:
            translate3d(0, var(--labelDefaultPosY), 0)
            scale(1);
    transform-origin: 0 0;
    transition:
            opacity var(--inputTransitionDuration) var(--inputTransitionTF),
            transform var(--inputTransitionDuration) var(--inputTransitionTF),
            visibility 0ms var(--inputTransitionDuration) var(--inputTransitionTF),
            z-index 0ms var(--inputTransitionDuration) var(--inputTransitionTF);
}

.input-text:placeholder-shown + .input-label {
    visibility: hidden;
    z-index: -1;
}

.input-text:not(:placeholder-shown) + .input-label,
.input-text:focus:not(:placeholder-shown) + .input-label {
    visibility: visible;
    z-index: 1;
    opacity: 1;
    margin-left: 5px;
    transform:
            translate3d(0, var(--labelTransformedPosY), 0)
            scale(var(--labelScaleFactor));
    transition:
            transform var(--inputTransitionDuration),
            visibility 0ms,
            z-index 0ms;
}