//sidebar
.sidebar-item-color {
    background-color: $color-secondary !important;
    border: none !important;

    &:hover {
        background-color: $color-tertiary !important;
    }

    &:focus {
        background-color: $color-tertiary !important;
    }

}

.body-row {
    margin: 0px !important;
}

#sidebar-container {
    min-height: 100vh;
    height: 100%;
    position: sticky;
    background: $color-secondary;
    width: 230px;
    top: 0;
    left: 0;
    z-index: 1;

    &.sidebar-collapsed {
        width: 70px;
    }

    .sidebar-nav {
        .sidebar-collapse {
            background: darken($color-secondary, 5%);
            padding: 1rem 1rem 1rem 1.7rem;
            border: none;
            color: #fff;
            width: 100%;
            text-align: left;
            font-size: 1rem;

            .svg-inline--fa {
                font-size: 1.3rem;
                margin-right: 0.3rem;
            }

            .sidebar-collapse-caption {
                margin-left: 1rem;
            }
            &:focus {
                outline: none;
            }
        }

        .sidebar-nav-item {
            color: #fff;
            text-decoration: none;
            display: block;
            position: relative;
            white-space: nowrap;

            .sidebar-nav-item-link {
                padding: 1rem 1rem 1rem 1.5rem;
                display: block;
                color: #fff;
                text-decoration: none;

                &.active {
                    background: darken($color-secondary, 5%);

                    &:before {
                        content: '';
                        position: absolute;
                        height: 100%;
                        width: 5px;
                        background: #fff;
                        left: 0;
                        top: 0;
                    }
                }
            }

            .sidebar-nav-item-sub {
                display: none;
                position: absolute;
                left: 100%;
                top: 0;
                background: $color-secondary;
            }

            &:hover .sidebar-nav-item-sub {
                display: block;
            }

            &:hover {
                background: darken($color-secondary, 5%);
            }
        }

        .sidebar-nav-icon {
            display: inline-block;
            margin-right: 1rem;
        }
    }

    &.sidebar-collapsed {
        .sidebar-collapse {

            .sidebar-collapse-caption {
                display: none;
            }
        }

        .sidebar-nav-item-name {
            display: none;
        }
    }
}
